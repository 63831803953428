@import url('https://fonts.googleapis.com/css2?family=Oldenburg&display=swap');

@font-face {
    font-family: 'natsregular';
    src: url('../assets/fonts/nats-regular-webfont.woff2') format('woff2'),
         url('../assets/fonts/nats-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

body {
    font-family: 'natsregular';
    width: 100vw;
    height: 100vh;  
    background-color: #000000;
    background-size: cover; 
    overflow-x: hidden;
    color: #fff;
    display: flex;
    justify-content: center;
}

body::-webkit-scrollbar {
    width: .35em;
  }
   
body::-webkit-scrollbar-track {
box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
background-color: darkgrey;
outline: 1px solid slategrey;
border-radius: 50px;
}

.hamburger {
    position: absolute;
    top: 33px;
    right: 25px;
    z-index: 99999;
}

.hamburger-inner,
.hamburger-inner:before,
.hamburger-inner:after,
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner:after,
.hamburger.is-active .hamburger-inner:before {
    background: #fff;
    width: 30px;
}

#menu{
    font-family: 'Oldenburg', serif;
    position: absolute;
    z-index: 99999;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 800px;
    max-width: 90vw;
    height: 85vh;
    color:#000;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 65px;
    display: none;
}

#menu p {
    text-align: justify;
    margin: 20px auto 40px auto;
}

#menu ul {
    list-style: none;
    font-size: 2em;
}

#menu-footer {
    position: absolute;
    bottom: 5px;
    font-size: 1em;
}

.material-symbols-outlined { 
    vertical-align: bottom;
}

#logo {
    font-family: 'Oldenburg', serif;
    color: #fff;    
    position: absolute;
    top: 45px;
    text-shadow: 
        0.05em 0 0 rgba(183, 0, 255, 0.75),
        -0.025em -0.05em 0 rgba(255, 255, 255, 0.75),
        0.025em 0.05em 0 rgba(0, 0, 255, 0.75);    
    animation: glitch 500ms ease-in-out reverse infinite;
    text-align: center;
}

#logo h1 {
    font-size: 2em;
    letter-spacing: 2.5px;
    font-weight: 400;
}

@keyframes glitch {
    0% {
        text-shadow: 
            0.05em 0 0 rgba(183, 0, 255, 0.75),
            -0.05em -0.05em 0 rgba(255, 255, 255, 0.75),
            0.05em 0.05em 0 rgba(0, 0, 255, 0.75);
    }
    25% {
        text-shadow: 
            -0.05em -0.05em 0 rgba(179, 255, 0, 0.75),
            0.05em 0.05em 0 rgba(255, 255, 255, 0.75),
            -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);  
    }
    50% {
        text-shadow: 
            0.05em 0.05em 0 rgba(179, 255, 0, 0.75),
            0.05em 0 0 rgba(255, 255, 255, 0.75),
            -0.05em 0.05em 0 rgba(0, 0, 255, 0.75);
    }
    100% {
        text-shadow: 
            -0.05em 0 0 rgba(179, 255, 0, 0.75),
            -0.05em -0.05em 0 rgba(255, 255, 255, 0.75),
            -0.05em -0.05em 0 rgba(0, 0, 255, 0.75); 
    }
}

#creepy-music{    
    margin: 50px auto;
    display: flex;
    justify-content: center;
    gap: 15px;
}

#volumeOn {
    display: none;
}

.static, #particles-js, .haunted-house {
    width: 100vw;
    height: 100vh;
    position: fixed;
}

.static {
    position: fixed;
    top: -300px;
    left:-300px;
    right: 0;
    bottom: 0;
    width: 1500%;
    height: 1500%;
    background: transparent url('/src/assets/img/static.png') 0 0 repeat; 
    background-blend-mode: multiply;
    opacity: 0.15;
    animation: tv-static 1s steps(8,end) infinite both;
    z-index: -1;
} 

@keyframes tv-static {
    0% {
        transform: translateX(0px,0px); }
      10% {
        transform: translate(-100px, 100px);
      }
      20% {
        transform: translate(150px, -100px);
      }
      30% {
        transform: translate(-100px,100px);
      }
      40% {
        transform: translate(100px, -150px);
      }
      50% {
        transform: translate(-100px, 200px);
      }
      60% {
        transform: translate(-200px, -100px);
      }
      70% {
        transform: translateY(50px, 100px);
      }
      80% {
        transform: translate(100px, -150px);
      }
      90% {
        transform: translate(0px, 200px);
      }
      100% {
        transform: translate(-100px, 100px);
      }
}

.haunted-house {  
    background: url('../assets/img/haunted-house.jpg') top  center no-repeat;
    background-size: cover;
    filter: grayscale(100%);
    opacity: 0.15;
}

#container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    height: auto;
    width: 800px;
    max-width: 90vw;
    position: relative;
}

#header-intro, #setup-container {
    font-size: 4em;
    letter-spacing: 2.5px;
    text-align: center;
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
}

#discover, #beware, #past {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

#discover {
    opacity: 0;
    animation: fadeIn 4s ease-in-out;
}

#beware {
    opacity: 0;
    animation: fadeIn 4s ease-in-out 4s forwards;
}

#past {
    opacity: 0;
    animation: fadeIn 4s ease-in-out 8s forwards;
} 

#setup-container {    
    opacity: 0;
    animation: fadeIn 4s ease-in-out 0s forwards;
}

@keyframes fadeIn {
    0%,100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
}

#alertModal {
    font-family: 'Oldenburg', serif;
    background-color: #d6d1c3;
    background-image: url("https://www.transparenttextures.com/patterns/concrete-wall.png");
    color: #000;
    padding: 20px 20px 60px 20px;
    z-index: 99999;
    width: 90vw;
    max-width: 600px;
    height: auto;
    font-size: 1em;
    display: none;
}

#alertModal p {
    line-height: 1.5;
    text-align: center;
}

#close {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
}

#look-up {
    display: none;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    opacity: 0;
    animation: fadeInStay 3s ease-in-out 0s forwards;
}

#look-up h1 {
    font-family: 'natsregular';
    font-weight: 400;
    font-size: 2.5em;
    letter-spacing: 3px;
    text-align: center;
}

input::placeholder, input, select {
    color: #000;
    font-family: 'Oldenburg', serif;
}

option {
    font-family: 'Oldenburg', serif;
}

#form-inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    gap: 15px;
}

select, option {
    -webkit-appearance: none;
}

.option {
    background: rgba(255,255,255,0.5);
    border: 1px solid #fff;
    width: 200px;
    max-width: 200px;
    height: auto;
    padding: 10px 20px;
    border-radius: 50px;
    color: #000;
}

.option:focus {
    outline: none;
}

input, option, .btn {
    height: 40px;
}

.btn {
    font-family: 'natsregular';
    background: rgba(255,255,255,0.5);
    color: #000;
    border: 1px solid #fff;
    padding:5px 25px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 0;
}

.btn, .story-text, .story{
    font-size: 1.5em;
}

.btn:hover, #alertModal:hover {
    cursor: pointer;
}

.random-btn, .donate-btn {
    background: #000000;
    color: #fff;
    border: 2px solid #3a3a3a;
    margin: 10px auto;
}

#story-container {
    display: none;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    max-width: 600px;
    position: absolute;
    top: 200px;
    color: #fff;
    padding: 15px;
    animation: fadeInStay 3s ease-in-out 0s forwards;
}

.btnGlow {
    box-shadow: 0px 0px 5px #eefc9d;
    animation: pulse 2s ease-in-out 0s infinite;
}

@keyframes pulse {
    0%{ transform: scale(1)}
    50%{ transform: scale(1.05) }
    100% { transform: scale(1)   }
}

#refresh-btn {
    font-size: 1.5em;
    z-index: 99999;
    width: 200px;
    margin: 20px auto 40px auto;
    display: none;
    animation: fadeInStay 3s ease-in-out 0s forwards;    
}

#AI-enhanced-story {
    text-align: left;
    margin-bottom: 50px;
    animation: fadeInStay 3s ease-in-out 0s forwards; 
} 

@keyframes fadeInStay {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

#rendered-story p {
    display: inline-block;
}

#haunted-title {
    font-family: 'Oldenburg', serif;
    text-transform: capitalize;
    margin-bottom: 25px;
    text-align: center;
    font-size: 2em;
    letter-spacing: 1px;
    line-height: 65px;
}

#the-story {
    display: none !important;
}

.story-label {
    font-size: 1.5em;
    text-transform: uppercase;
    margin: 10px 10px 30px 0px;
}

.story-text {
    font-size: 1em;
}

.story {   
    line-height: 28px;
    letter-spacing: .75px;
}
 
/*************************
    Media Queries
*************************/

@media (max-width: 480px) {
    #look-up h1 {
      font-size: 1.5em;
    }
    #logo{
        top: 60px;
    }

    .hamburger {
        top: 10px;
        right: 5px;
    }

    #menu{
        padding: 10px 20px;
        height: 100%;
        margin: 50px auto;
    }
    #header-intro, #setup-container {
        line-height: 1;
        top: 40%;        
    }

    #haunted-story {
        padding: 10px 25px;
    }
}